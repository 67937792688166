import { useState } from 'react';
import { Card } from '../card/card';
import { Button } from '../button/button';
import { RequestModal } from '../requestModal/requestModal';
import useMediaQuery from '@mui/material/useMediaQuery';
import cardOne from '../../utils/images/cards-proposals/card-one.svg';
import cardTwo from '../../utils/images/cards-proposals/card-two.svg';
import cardThree from '../../utils/images/cards-proposals/card-three.svg';
import cardFour from '../../utils/images/cards-proposals/card-four.svg';
import './blockOfProposals.scss';
import { Partners } from '../partners/partners';

export const BlockOfProposals = () => {
    const Mobile = useMediaQuery('(max-width:500px)');
    const [activeModal, setActiveModal] = useState(false);

    const onOpenModal = () => {
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    return (
        <div className='block__wrapper'>
            <div className=' __wrapper'>
                <section className='block'>
                    <div className='block__title'>вы получите</div>
                    <div className='block__row'>
                        <Card className='block__card--medium'>
                            <img
                                src={cardOne}
                                alt='Новые заказы'
                                className='block__card-image block__card-image--small-rotate'
                            />
                            <div className='block__card-content'>
                                {!Mobile && (
                                    <div className='block__card-title'>
                                        Новые заказы <br /> всегда под рукой
                                    </div>
                                )}
                                {Mobile && (
                                    <div className='block__card-title'>
                                        Новые <br /> заказы <br /> всегда под
                                        рукой
                                    </div>
                                )}
                                <div className='block__card-text'>
                                    Быстрый доступ к заказам <br /> доставки из
                                    любого окна
                                </div>
                            </div>
                        </Card>

                        <Card className='block__card--small'>
                            <img
                                src={cardTwo}
                                alt='Панель управления'
                                className='block__card-image'
                            />
                            <div className='block__card-content'>
                                {!Mobile && (
                                    <div className='block__card-title'>
                                        Удобную панель <br /> управления
                                    </div>
                                )}
                                {Mobile && (
                                    <div className='block__card-title'>
                                        Удобную <br /> панель <br /> управления
                                    </div>
                                )}
                                <div className='block__card-text'>
                                    Работать будет еще проще
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className='block__row'>
                        <Card className='block__card--small'>
                            <img
                                src={cardThree}
                                alt='Уведомления'
                                className='block__card-image'
                            />
                            <div className='block__card-content'>
                                {!Mobile && (
                                    <div className='block__card-title'>
                                        Цветные и звуковые уведомления
                                    </div>
                                )}
                                {Mobile && (
                                    <div className='block__card-title'>
                                        Цветные <br /> и звуковые <br />{' '}
                                        уведомления
                                    </div>
                                )}
                                <div className='block__card-text'>
                                    Теперь вы не упустите ни одного заказа
                                </div>
                            </div>
                        </Card>

                        <Card className='block__card--medium'>
                            <img
                                src={cardFour}
                                alt='Управление заказами'
                                className='block__card-image'
                            />
                            <div className='block__card-content'>
                                {!Mobile && (
                                    <div className='block__card-title'>
                                        Возможность принятия, отмены и переноса
                                        заказа
                                    </div>
                                )}
                                {Mobile && (
                                    <div className='block__card-title'>
                                        Возможность <br /> принятия, <br />{' '}
                                        отмены и переноса заказа
                                    </div>
                                )}
                                <div className='block__card-text'>
                                    Весь этот функционал в одном месте
                                </div>
                            </div>
                        </Card>
                    </div>

                    <Button
                        theme='green'
                        className='block__button'
                        onClick={onOpenModal}>
                        Готовы узнать подробнее?
                    </Button>
                    <RequestModal active={activeModal} onClose={onCloseModal} />
                </section>
            </div>
            <Partners />
        </div>
    );
};
