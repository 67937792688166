import { FC, useState } from "react";
import image from "../../utils/images/modal/modalImage.svg";
import { Button } from "../button/button";
import { Modal } from "../modal/modal";
import { Field, Formik, FormikHelpers, Form } from "formik";
import { Input } from "../input/input";
import ReactLoading from "react-loading";
import axios from "axios";
import { requestFormSchema } from "./validationRequest";
import "./requestModal.scss";
import { TERMS_OF_USE } from "../../utils/const/const";

interface IRequestModal {
  /** Видимость модального окна. */
  active: boolean;

  /** Ф-я закрытия модального окна. */
  onClose(): void;
}

interface IValues {
  name: string;
  company: string;
  phone: string;
}

enum Row {
  Name = "Имя",
  Company = "Название компании",
  Phone = "Номер телефона",
}

export const RequestModal: FC<IRequestModal> = (props) => {
  const { active, onClose } = props;
  const [successful, setSuccessful] = useState(false);

  const initialValues: IValues = {
    name: "",
    company: "",
    phone: "",
  };

  const onSubmit = (
    values: IValues,
    { resetForm, setSubmitting }: FormikHelpers<IValues>
  ) => {
    axios
      .post(
        "https://sheet.best/api/sheets/91cfd625-88e0-4e99-942e-daf51e71e309",
        {
          [Row.Name]: values.name,
          [Row.Company]: values.company,
          [Row.Phone]: `'${values.phone}`,
        }
      )
      .then(() => {
        resetForm();
        setSubmitting(false);
        setSuccessful(true);
      });
  };

  return (
    <Modal active={active} onClose={onClose} className="request-modal">
      <div className="request-modal__content">
        {!successful ? (
          <div className="request-modal__group-content">
            <div className="request-modal__title">давайте обсудим проект</div>
            <div className="request-modal__group-form">
              <Formik
                initialValues={initialValues}
                validationSchema={requestFormSchema}
                onSubmit={(values, actions) => onSubmit(values, actions)}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Ваше имя"
                      className="request-modal__input"
                    />
                    <Field
                      name="company"
                      component={Input}
                      placeholder="Название компании"
                      className="request-modal__input"
                    />
                    <Field
                      name="phone"
                      component={Input}
                      placeholder="+7"
                      type="tel"
                      className="request-modal__input"
                    />
                    {isSubmitting && (
                      <ReactLoading
                        type="bubbles"
                        color="#C0E709"
                        className="request-modal__loading"
                      />
                    )}
                    <Button
                      theme="green"
                      className="request-modal__button"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      отправить заявку
                    </Button>
                    <a href={TERMS_OF_USE} rel="noreferrer" target="_blank">
                      <div className="request-modal__small-text">
                        Отправляю заявку я соглашаюсь с Пользовательским
                        соглашением
                      </div>
                    </a>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        ) : (
          <div>
            <div className="request-modal__title">
              Заявка успешно отправлена
            </div>
            <div className="request-modal__text">
              Спасибо, наш менеджер в ближайшее время свяжется с вами для
              обсуждения проекта
            </div>
          </div>
        )}
        <img
          src={image}
          alt="Изображение человека"
          className="request-modal__image"
        />
      </div>
    </Modal>
  );
};
