import './descriptionItem.scss';
import { FC } from 'react';

interface IDescriptionItemProps {

    /** Порядковый номер элемента. */
    number: string;

    /** Заголовок элемента. */
    title: string;

    /** Контент элемента */
    content: string;
}

export const DescriptionItem: FC<IDescriptionItemProps> = (props) => {
    const { number, title, content } = props;
    
    return (
        <div className="description-item">
            <div className="description-item__number">{number}</div>
            <div className="description-item__title">{title}</div>
            <div className="description-item__content">{content}</div>
        </div>
    )
}