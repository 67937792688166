import { FC } from 'react';
import { FieldProps } from 'formik';
import clsx from 'clsx';
//@ts-ignore
import ReactInputMask from 'react-input-mask';
import './input.scss';


export type TIconInput = 'search' | 'password' | 'person';

export interface InputProps extends FieldProps {
    type?: 'tel';
    className?: string;
}

export const Input: FC<InputProps> = ({ field, form: { touched, errors }, className, type, ...props }) => {

    return (
        <div className={clsx('field', className)}>
            {
                !type && <input
                    type={type}
                    className={clsx('field__input', touched[field.name] && errors[field.name] && 'field__input--error')}
                    {...field} {...props} />
            }

            {
                type && <ReactInputMask
                    mask={'+7 (999) 999-9999'}
                    maskChar={null}
                    type={type}
                    className={clsx('field__input', touched[field.name] && errors[field.name] && 'field__input--error')}
                    {...field} {...props} />

            }

            {
                touched[field.name] && errors[field.name] &&
                <span className='field__caption'>
                    {String(errors[field.name])}
                </span>
            }
        </div>
    );
};