import { Button } from '../button/button';
import { ProductCard } from '../productCard/productCard';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { products } from '../../utils/lists/products';

import 'swiper/css';
import 'swiper/css/pagination';
import './products.scss';

export const Products = () => {
    const Mobile = useMediaQuery('(max-width:800px)');

    return (
        <div className='__wrapper'>
            <section className='products'>
                <div className='products__title'>Наши продукты</div>

                {!Mobile && (
                    <div className='products__group-card'>
                        {products.map((product) => (
                            <ProductCard
                                key={product.id}
                                className='products__card'>
                                <img
                                    src={product.imageProduct}
                                    alt={product.altImageProduct}
                                    className='products__card-image'
                                />

                                <div className='products__card-title-group'>
                                    <img
                                        src={product.iconProduct}
                                        alt='Иконка'
                                    />
                                    <div>
                                        <div className='products__card-title'>
                                            {product.title}
                                        </div>
                                        <div className='products__card-subtitle'>
                                            {product.subtitle}
                                        </div>
                                    </div>
                                </div>

                                <div className='products__card-text'>
                                    {product.description}
                                </div>

                                <Button
                                    theme='green'
                                    className='products__card-button'
                                    onClick={() => window.open(product.link)}>
                                    перейти
                                </Button>
                            </ProductCard>
                        ))}
                    </div>
                )}

                {Mobile && (
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1.2}
                        modules={[Pagination]}
                        pagination={{ el: '.swiper-custom-pagination' }}>
                        {products.map((product) => (
                            <SwiperSlide key={product.id}>
                                <ProductCard className='products__card'>
                                    <img
                                        src={product.imageProduct}
                                        alt={product.altImageProduct}
                                        className='products__card-image'
                                    />

                                    <div className='products__card-title-group'>
                                        <img
                                            src={product.iconProduct}
                                            alt='Иконка'
                                        />
                                        <div>
                                            <div className='products__card-title'>
                                                {product.title}
                                            </div>
                                            <div className='products__card-subtitle'>
                                                {product.subtitle}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='products__card-text'>
                                        {product.description}
                                    </div>

                                    <Button
                                        theme='green'
                                        className='products__card-button'
                                        onClick={() =>
                                            window.open(product.link)
                                        }>
                                        перейти
                                    </Button>
                                </ProductCard>
                            </SwiperSlide>
                        ))}
                        <div className='swiper-custom-pagination' />
                    </Swiper>
                )}
            </section>
        </div>
    );
};
