import eFood from '../images/products/eFood.svg';
import beInTouch from '../images/products/beInTouch.svg';
import evoPlugins from '../images/products/evoPlugins.svg';
import topper from '../../utils/images/products/topper.svg';

import iconOrange from '../images/evo-icons/orange.svg';
import iconViolet from '../images/evo-icons/violet.svg';
import iconGreen from '../images/evo-icons/green.svg';
import iconBlue from '../images/evo-icons/blue.svg';

import { BE_IN_TOUCH, EFOOD, EVO_PLUGINS, TOPPER } from '../const/const';

export const products = [
    {
        id: 1,
        imageProduct: eFood,
        altImageProduct: 'Доставка еды',
        iconProduct: iconOrange,
        title: 'Efood',
        subtitle: 'Доставка',
        description: 'Мы знаем, как ресторанам свести потери заказов к минимуму и зарабатывать на online доставке.',
        link: EFOOD,
    },
    {
        id: 2,
        imageProduct: evoPlugins,
        altImageProduct: 'Плагины',
        iconProduct: iconViolet,
        title: 'Evo Plugins',
        subtitle: 'Плагины',
        description: ' У нас есть понимание операционных процессов HoReCa, что позоляет нам делать не только стабильные, но и удобные плагины.',
        link: EVO_PLUGINS,
    },
    {
        id: 3,
        imageProduct: beInTouch,
        altImageProduct: 'Лояльность',
        iconProduct: iconGreen,
        title: 'Be In Touch',
        subtitle: 'Лояльность',
        description: 'Наши продукты предоставляют клиентам возможность анализировать поведение гостей и использовать эффективные маркетинговые механики.',
        link: BE_IN_TOUCH,
    },
    {
        id: 4,
        imageProduct: topper,
        altImageProduct: 'Мобильная разработка',
        iconProduct: iconBlue,
        title: 'Toppen',
        subtitle: 'Мобильная разработка',
        description: ' Создаем уникальные приложения с программой лояльности, функцией предзаказа, промокодами и алгоритмами персональных рекомендаций.',
        link: TOPPER,
    }


]