export const PRESENTATION_LINK =
    'https://drive.google.com/file/d/1QLQYyj7kejs0b5BTZo-gGV9Ccv6XopwO/view?pli=1';

export const EVO_PLUGINS =
    'https://drive.google.com/file/d/1nylqPPAxtNKdcheTntAuYVjWSC9ZRmPl/view?usp=sharing';
export const EFOOD = 'https://efood.dev/';
export const BE_IN_TOUCH = 'https://beintouch.evosoft.dev/';
export const TOPPER =
    'https://drive.google.com/file/d/1u_y3mhmoAtnBGY1bXgvuIkARW_Ski5Tx/view?usp=sharing';

export const CONTRACT_OFFER =
    'https://drive.google.com/file/d/1qhAEYiD1QnobSVYCll3RcojYg-O0D4Rw/view?usp=sharing';

export const PHONE = '+7 (983) 575 00 01';
export const TELEGRAM = 'https://t.me/dvjakunin';
export const TERMS_OF_USE =
    'https://drive.google.com/file/d/12olFnbTPRCs1vyLEFtKLP2EyOE13n8xG/view';
