import { DescriptionItem } from '../descriptionItem/descriptionItem';
import descriptionImage from '../../utils/images/description.png';
import './descriptionOfWork.scss';

export const DescriptionOfWork = () => {
    return (
        <div className='description__wrapper'>
            <div className='__wrapper'>
                <section className='description'>
                    <div className='description__content'>
                        <div className='description__content-title'>
                            мы за комплексный подход
                        </div>

                        <div className='description__content-row'>
                            <DescriptionItem
                                number='01'
                                title='Система версионирования'
                                content='Обеспечивает быстрый запуск и установку плагина, 
                         позволяет обновлять наши продукты в режиме онлайн'
                            />

                            <DescriptionItem
                                number='02'
                                title='Поддержка работы на Windows 7'
                                content='Работаем с оборудованием на Windows
                         7 и помогаем сделать их работу лучше и удобнее'
                            />
                        </div>

                        <div className='description__content-row'>
                            <DescriptionItem
                                number='03'
                                title='Онлайн мониторинг'
                                content='У нас выстроена система мониторинга состояния плагина, 
                        что позволяет делать продукт лучше до момента поступления обращений'
                            />

                            <DescriptionItem
                                number='04'
                                title='Постоянное развитие'
                                content='Мы постоянно улучшаем наш продукт и 
                        выпускаем обновления для повышения качества его использования'
                            />
                        </div>

                        <DescriptionItem
                            number='05'
                            title='Персональный менеджер и тех.поддержка'
                            content='Мы всегда готовы ответить на все ваши вопросы в кратчайшие сроки'
                        />
                    </div>

                    <img
                        src={descriptionImage}
                        alt='Описание подходов к работе'
                        className='description__image'
                    />
                </section>
            </div>
        </div>
    );
};
