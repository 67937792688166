import mapImage from '../../utils/images/map.png';
import cardOne from '../../utils/images/cards-advantage/card1.png';
import cardTwo from '../../utils/images/cards-advantage/card2.png';
import cardThree from '../../utils/images/cards-advantage/card3.png';
import cardFour from '../../utils/images/cards-advantage/card4.png';
import cardFive from '../../utils/images/cards-advantage/card5.png';
import cardSix from '../../utils/images/cards-advantage/card6.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import { Card } from '../card/card';

import 'swiper/css';
import 'swiper/css/pagination';
import './blockAdvantage.scss';

export const BlockAdvantage = () => {
    const Mobile = useMediaQuery('(max-width:800px)');

    return (
        <div className='__wrapper'>
            <section className='block-advantage'>
                <div className='block-advantage__header'>
                    <div>
                        <div className='block-advantage__title'>Почему мы?</div>
                        <img
                            src={mapImage}
                            alt='Местоположения работающих плагинов'
                            className='block-advantage__header-image'
                        />
                    </div>
                    <div>
                        <div className='block-advantage__header-text'>
                            6 стран
                        </div>
                        <div className='block-advantage__header-text'>
                            71 город
                        </div>
                        <div className='block-advantage__header-text'>
                            80+ плагинов
                        </div>
                    </div>
                </div>

                <div className='block-advantage__title'>преимущества</div>

                {!Mobile && (
                    <>
                        <div className='block-advantage__row-card'>
                            <Card className='block-advantage__card'>
                                <img src={cardOne} alt='Количество плагинов' />
                                <div className='block-advantage__card-text'>
                                    В рамках нашей деятельности было реализовано
                                    <span className='block-advantage__card-text-green'>
                                        &nbsp;80+ плагинов&nbsp;
                                    </span>
                                    различной сложноти и функциональности,
                                    которые решают различные задачи
                                </div>
                            </Card>

                            <Card className='block-advantage__card'>
                                <img src={cardTwo} alt='Количество плагинов' />
                                <div className='block-advantage__card-text'>
                                    Собственная система версионирования
                                    плагинов, для возможности
                                    <span className='block-advantage__card-text-green'>
                                        &nbsp;удаленного online обновления&nbsp;
                                    </span>
                                    плагинов на терминалах
                                </div>
                            </Card>
                        </div>

                        <div className='block-advantage__row-card'>
                            <Card className='block-advantage__card'>
                                <img
                                    src={cardThree}
                                    alt='Количество плагинов'
                                />
                                <div className='block-advantage__card-text'>
                                    Мы являемся технологическими
                                    <span className='block-advantage__card-text-green'>
                                        &nbsp;партнерами iiko&nbsp;
                                    </span>
                                    — это позволяет нам быть в курсе всех
                                    обновлений и новых версий iiko
                                </div>
                            </Card>

                            <Card className='block-advantage__card'>
                                <img src={cardFour} alt='Количество плагинов' />
                                <div className='block-advantage__card-text'>
                                    В нашем портфолио есть плагины, которые
                                    работают на более чем
                                    <span className='block-advantage__card-text-green'>
                                        &nbsp;1200+ торговых точках&nbsp;
                                    </span>
                                    одновременно
                                </div>
                            </Card>
                        </div>

                        <div className='block-advantage__row-card'>
                            <Card className='block-advantage__card'>
                                <img src={cardFive} alt='Количество плагинов' />
                                <div className='block-advantage__card-text'>
                                    Глубокое понимание операционных процессов
                                    HoReCa, что позоляет нам делать не только
                                    <span className='block-advantage__card-text-green'>
                                        &nbsp;стабильные&nbsp;
                                    </span>
                                    плагины, но и
                                    <span className='block-advantage__card-text-green'>
                                        &nbsp;удобные&nbsp;
                                    </span>
                                </div>
                            </Card>

                            <Card className='block-advantage__card'>
                                <img src={cardSix} alt='Количество плагинов' />
                                <div className='block-advantage__card-text'>
                                    Опыт разработки плагинов под
                                    <span className='block-advantage__card-text-green'>
                                        &nbsp;требования&nbsp;
                                    </span>
                                    системы версионирования со стороны
                                    <span className='block-advantage__card-text-green'>
                                        &nbsp;iiko&nbsp;
                                    </span>
                                    для внутренней дистрибуции через репозиторий
                                    iiko
                                </div>
                            </Card>
                        </div>
                    </>
                )}

                {Mobile && (
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1.2}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                        }}
                        pagination={{ el: '.swiper-custom-pagination' }}
                        modules={[Pagination, Autoplay]}>
                        <SwiperSlide>
                            <Card className='block-advantage__card'>
                                <img src={cardOne} alt='Количество плагинов' />
                                <div className='block-advantage__card-text'>
                                    В рамках нашей деятельности было
                                    реализовано&nbsp;
                                    <span className='block-advantage__card-text-green'>
                                        80+ плагинов
                                    </span>
                                    &nbsp;различной сложноти и функциональности,
                                    которые решают различные задачи
                                </div>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card className='block-advantage__card'>
                                <img src={cardTwo} alt='Количество плагинов' />
                                <div className='block-advantage__card-text'>
                                    Собственная система версионирования
                                    плагинов, для возможности&nbsp;
                                    <span className='block-advantage__card-text-green'>
                                        удаленного online обновления
                                    </span>
                                    &nbsp; плагинов на терминалах
                                </div>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card className='block-advantage__card'>
                                <img
                                    src={cardThree}
                                    alt='Количество плагинов'
                                />
                                <div className='block-advantage__card-text'>
                                    Мы являемся технологическими&nbsp;
                                    <span className='block-advantage__card-text-green'>
                                        партнерами iiko
                                    </span>
                                    &nbsp;— это позволяет нам быть в курсе всех
                                    обновлений и новых версий iiko
                                </div>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card className='block-advantage__card'>
                                <img src={cardFour} alt='Количество плагинов' />
                                <div className='block-advantage__card-text'>
                                    В нашем портфолио есть плагины, которые
                                    работают на более чем&nbsp;
                                    <span className='block-advantage__card-text-green'>
                                        1200+ торговых точках
                                    </span>
                                    &nbsp; одновременно
                                </div>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card className='block-advantage__card'>
                                <img src={cardFive} alt='Количество плагинов' />
                                <div className='block-advantage__card-text'>
                                    Глубокое понимание операционных процессов
                                    HoReCa, что позоляет нам делать не
                                    только&nbsp;
                                    <span className='block-advantage__card-text-green'>
                                        стабильные
                                    </span>
                                    &nbsp;плагины, но и&nbsp;
                                    <span className='block-advantage__card-text-green'>
                                        &nbsp;удобные
                                    </span>
                                </div>
                            </Card>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card className='block-advantage__card'>
                                <img src={cardSix} alt='Количество плагинов' />
                                <div className='block-advantage__card-text'>
                                    Опыт разработки плагинов под&nbsp;
                                    <span className='block-advantage__card-text-green'>
                                        требования
                                    </span>
                                    &nbsp; системы версионирования со
                                    стороны&nbsp;
                                    <span className='block-advantage__card-text-green'>
                                        iiko
                                    </span>
                                    &nbsp;для внутренней дистрибуции через
                                    репозиторий iiko
                                </div>
                            </Card>
                        </SwiperSlide>
                        <div className='swiper-custom-pagination' />
                    </Swiper>
                )}
            </section>
        </div>
    );
};
