import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import close from '../../utils/images/modal/Close.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import './modal.scss';

interface ModalProps {
    active: boolean;
    children?: ReactNode;
    className?: string;

    onClose(): void;
}

export const Modal: FC<ModalProps> = ({
    active,
    children,
    onClose,
    className,
}) => {
    const Mobile = useMediaQuery('(max-width:800px)');

    return (
        <div
            className={clsx('modal', active && 'modal--active', Mobile && 'modal--mobile')}>
            <div
                className={clsx(
                    'modal__content',
                    active && 'modal__content--active',
                    className
                )}
                onClick={(e) => e.stopPropagation()}>
                {
                    <button className='modal__button-close' onClick={onClose}>
                        <img src={close} alt='Иконка закрытия модального окна' />
                    </button>
                }
                {children}
            </div>
        </div>
    );
};
