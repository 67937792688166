import { FC } from "react";
import './card.scss';
import clsx from "clsx";

interface ICardProps {

    /** Содержание карточки */
    children: any;

    /** Дополнительные стили */
    className?: string;
}

export const Card: FC<ICardProps> = (props) => {
    const { children, className } = props;

    return (
        <div className={clsx('card', className)}>
            {children}
        </div>
    )
}