import { Button } from '../button/button';
import { FC, Ref, useEffect, useRef, useState } from 'react';
import { RequestModal } from '../requestModal/requestModal';
import { PRESENTATION_LINK } from '../../utils/const/const';
import logo from '../../utils/images/logo.svg';
import Pr from '../../utils/images/button/pr';
import './header.scss';

interface Props {
    onLogoRef: Ref<HTMLDivElement>;
    onHeightChange?: (height: number) => void;
    onHeaderTitleRef?: Ref<HTMLDivElement>;
}

export const Header: FC<Props> = (props) => {
    const { onHeightChange, onHeaderTitleRef, onLogoRef } = props;

    const [activeModal, setActiveModal] = useState(false);

    const headerRef = useRef<HTMLHeadElement>(null);
    const height = headerRef.current?.getBoundingClientRect().height;

    useEffect(() => {
        if (height) {
            onHeightChange?.(height);
        }
    }, [height, onHeightChange]);

    const onOpenModal = () => {
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    const openPresentation = () => {
        window.open(PRESENTATION_LINK);
    };

    return (
        <header ref={headerRef} className='header __wrapper'>
            <div className='header__content'>
                <div ref={onLogoRef}>
                    <img className='header__logo' src={logo} alt='Логотип' />
                </div>
                <div ref={onHeaderTitleRef} className='header__title'>
                    Теперь все заказы под рукой
                </div>
                <div className='header__subtitle'>
                    Плагин MiniCallCenter позволяет быстро подтверждать
                    и отменять заказы с обработкой причин отмены заказов
                </div>

                <div className='header__buttons-group'>
                    <Button theme='green' onClick={onOpenModal}>
                        оставить заявку
                    </Button>
                    <Button
                        theme='colorless'
                        svg={<Pr />}
                        onClick={openPresentation}>
                        получить презентацию
                    </Button>
                </div>
            </div>

            <RequestModal active={activeModal} onClose={onCloseModal} />
        </header>
    );
};
