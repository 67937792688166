export const questions = [
    {
        id: 1,
        question: 'На какие метрики влияет плагин?',
        answer: 'Использование плагина влияет на следующие метрики: \n — Увеличивается средняя скорость принятия заказов. \n — Увеличивается число заказов, приготовленных в срок. \n — Уменьшается число пропущенных и не обработанных заказов. \n — Уменьшается число отмененных заказов по причине отказа гостя.',
    },
    {
        id: 2,
        question: 'Видит ли кассир уведомления о поступлении заказов?',
        answer: 'Да, плагин предусматривает окна разных цветов (зеленый — заказ только оформили, оранжевый — обращаем внимание на заказ, красный — заказ просрочен) + имеется функция звуковых уведомлений.',
    },
    {
        id: 3,
        question: 'Хочу получить плагин, какие мои дальнейшие действия?',
        answer: 'Вы можете оставить заявку на сайте и с вами свяжутся наши специалисты. Далее, необходимо будет заключить договор и передать плагин в ваше использование.',
    },
    {
        id: 4,
        question: 'Смогу ли я самостоятельно установить плагин?',
        answer: 'Да. Мы подготовили удобные инструкции по установке, как в текстовом, так и видео формате.',
    },
];
