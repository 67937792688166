export default function Teleg() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'>
            <path
                d='M13.9765 1.03554L11.4267 12.1731C11.3068 12.6977 10.7518 12.9679 10.2969 12.7189L7.07218 10.9544L5.54733 13.5984C5.13237 14.319 4.08248 14.0064 4.08248 13.1639V10.2179C4.08248 9.99011 4.17247 9.77286 4.32745 9.61391L10.6118 3.25564C10.6068 3.17616 10.5268 3.10728 10.4468 3.16556L2.94759 8.69726L0.42784 7.31963C-0.162101 6.99642 -0.137104 6.09037 0.472836 5.80954L12.8966 0.0712067C13.4915 -0.204318 14.1315 0.362627 13.9765 1.03554Z'
                fill='black'
            />
        </svg>
    );
}
