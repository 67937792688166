import useMediaQuery from '@mui/material/useMediaQuery';
import { Button } from '../button/button';
import { useState } from 'react';
import {
    CONTRACT_OFFER,
    PHONE,
    PRESENTATION_LINK,
    TELEGRAM,
} from '../../utils/const/const';
import { RequestModal } from '../requestModal/requestModal';
import accountManager from '../../utils/images/ContactsPhoto.jpg';
import './footer.scss';
import Arrow from '../../utils/images/button/arrow';
import Pr from '../../utils/images/button/pr';

export const Footer = () => {
    const Mobile = useMediaQuery('(max-width:800px)');
    const [activeModal, setActiveModal] = useState(false);

    const onOpenModal = () => {
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    const openPresentation = () => {
        window.open(PRESENTATION_LINK);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className='__wrapper'>
            <footer className='footer'>
                <div className='footer__content'>
                    <div className='footer__content-info'>
                        <div className='footer__content-info-title'>
                            Начать просто
                        </div>
                        <div className='footer__content-info-text'>
                            Осталось всего лишь пара шагов до автоматизации
                            заказов
                        </div>
                        <div className='footer__content-info-group-button'>
                            <Button theme='green' onClick={onOpenModal}>
                                Оставить заявку
                            </Button>
                            <Button
                                theme='colorless'
                                svg={<Pr />}
                                onClick={openPresentation}>
                                Получить презентацию
                            </Button>
                        </div>
                    </div>

                    <div className='footer__content-contact'>
                        <img src={accountManager} alt='Денис Якунин' />
                        <div>
                            <div className='footer__content-contact-name'>
                                Денис Якунин
                            </div>
                            <div className='footer__content-contact-text'>
                                Коммерческий директор
                                {Mobile && (
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href={TELEGRAM}
                                        className='footer__content-contact-link-text'>
                                        Консультация
                                    </a>
                                )}
                            </div>
                            <a
                                href={`tel:${PHONE}`}
                                className='footer__content-contact-text'>
                                +7 (987) 654 32 21
                            </a>

                            {!Mobile && (
                                <div className='footer__content-contact-link'>
                                    <a
                                        href={TELEGRAM}
                                        rel='noreferrer'
                                        target='_blank'
                                        className='footer__content-contact-link-text'>
                                        Получить консультацию
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className='footer__bottom'>
                    <div className='footer__bottom-group'>
                        {Mobile && (
                            <a
                                href={CONTRACT_OFFER}
                                rel='noreferrer'
                                target='_blank'
                                className='footer__bottom-grey-link'>
                                Договор оферты
                            </a>
                        )}
                        <div className='footer__bottom-text'>
                            Все права защищены © 2023,&nbsp;
                        </div>
                        <a
                            href='https://evosoft.dev/'
                            rel='noreferrer'
                            target='_blank'
                            className='footer__bottom-green-link'>
                            evosoft
                        </a>
                    </div>
                    <div className='footer__bottom-group'>
                        {!Mobile && (
                            <a
                                href={CONTRACT_OFFER}
                                rel='noreferrer'
                                target='_blank'
                                className='footer__bottom-grey-link'>
                                Договор оферты
                            </a>
                        )}
                        <Button
                            theme='colorless'
                            svg={<Arrow />}
                            className='footer__bottom-button'
                            onClick={scrollToTop}>
                            НАВЕРХ
                        </Button>
                    </div>
                </div>
                <RequestModal active={activeModal} onClose={onCloseModal} />
            </footer>
        </div>
    );
};
