import { Header } from '../components/header/header';
import { BlockOfProposals } from '../components/blockOfProposals/blockOfProposals';
import { DescriptionOfWork } from '../components/descriptionOfWork/descriptionOfWork';
import { BlockAdvantage } from '../components/blockAdvantage/blockAdvantage';
import { Products } from '../components/products/products';
import { Tariffs } from '../components/tariffs/tariffs';
import { AboutWork } from '../components/aboutWork/aboutWork';
import { Questions } from '../components/questions/questions';
import { Footer } from '../components/footer/footer';
import { Button } from '../components/button/button';
import { InView, useInView } from 'react-intersection-observer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { TELEGRAM } from '../utils/const/const';
import clsx from 'clsx';
import videoHeader from '../utils/videos/video2.mp4';
import smallAccountManager from '../utils/images/ContactsPhoto.jpg';
import modileIcon from '../utils/images/main-telegram.svg';
import logo from '../utils/images/logo.svg';
import './main.scss';
import Teleg from '../utils/images/button/telegram';
import { RequestModal } from '../components/requestModal/requestModal';

export const MainPage = () => {
    const Mobile = useMediaQuery('(max-width:800px)');
    const { ref: handleHeaderTitleRef, inView: headerTitleInView } =
        useInView(undefined);
    const { ref: handleLogoRef, inView: logoInView } = useInView(undefined);
    const [footerInView, setFooterInView] = useState(false);
    const [videoHeight, setVideoHeight] = useState(0);
    const [activeModal, setActiveModal] = useState(false);

    const onOpenModal = () => {
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    const handleHeaderHeight = (height: number) => {
        setVideoHeight(height + window.innerHeight * 0.2);
    };

    const openTelegram = () => {
        window.open(TELEGRAM);
    };

    return (
        <>
            <div
                style={{ height: videoHeight }}
                className='header__video-wrapper'>
                <video
                    style={{ height: videoHeight }}
                    src={videoHeader}
                    autoPlay
                    muted
                    loop
                    playsInline
                    className='header__video-media'
                />
            </div>
            <div className='main__wrapper'>
                <Header
                    onLogoRef={handleLogoRef}
                    onHeaderTitleRef={handleHeaderTitleRef}
                    onHeightChange={handleHeaderHeight}
                />

                <main>
                    <BlockOfProposals />
                    <DescriptionOfWork />
                    <BlockAdvantage />
                    <Products />
                    <AboutWork />
                    <Tariffs />
                    <Questions />
                </main>

                <InView onChange={setFooterInView}>
                    <Footer />
                </InView>

                {!logoInView && Mobile && (
                    <div className='fixed-header'>
                        <img
                            className='fixed-header__logo'
                            src={logo}
                            alt='Логотип'
                        />
                        <Button
                            onClick={onOpenModal}
                            theme='colorless'
                            className='fixed-header__button'>
                            Подключить
                        </Button>
                    </div>
                )}

                <div
                    className={clsx(
                        'main__consultation-block',
                        (footerInView || headerTitleInView) &&
                            'main__consultation-block--hidden'
                    )}>
                    {Mobile && (
                        <a href={TELEGRAM} rel='noreferrer' target='_blank'>
                            <img
                                src={modileIcon}
                                alt='Иконка телеграм'
                                className='main__consultation-block-image'
                            />
                        </a>
                    )}
                    {!Mobile && (
                        <>
                            <img
                                src={smallAccountManager}
                                alt='Фото аккаунт менеджера'
                                className='main__consultation-block-image'
                            />
                            <Button
                                theme='green'
                                svg={<Teleg />}
                                onClick={openTelegram}
                                className='main__consultation-block-button'>
                                Получить консультацию
                            </Button>
                        </>
                    )}
                </div>
            </div>
            <RequestModal active={activeModal} onClose={onCloseModal} />
        </>
    );
};
