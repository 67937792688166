import imageAboutWork from '../../utils/images/aboutWork.png';
import './aboutWork.scss';

export const AboutWork = () => {
    return (
        <div className='__wrapper'>
            <section className='about-work'>
                <div className='about-work__title'>как это работает</div>

                <iframe
                    className='about-work__frame'
                    src='https://www.youtube.com/embed/8Uo6EvkML80'
                    title='YouTube video player'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowFullScreen
                />
            </section>
        </div>
    );
};
