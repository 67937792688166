import { FC, MouseEventHandler } from 'react';
//@ts-ignore
import { Collapse } from 'react-collapse';
import clsx from 'clsx';
import './questionItem.scss';

interface IQuestionItemProps {
    /** Вопрос. */
    question: string;

    /** Ответ на вопрос. */
    answer: string;

    id: number;

    /** Открыт/закрыт вопрос. */
    activeItem: null | number;

    /** Переключатель активности вопроса. */
    setActiveItem(id: number): void;
}

export const QuestionItem: FC<IQuestionItemProps> = (props) => {
    const { question, answer, id, activeItem, setActiveItem } = props;

    return (
        <div
            className={clsx(
                'question-item',
                activeItem === id && 'question-item--open'
            )}
            onClick={() => setActiveItem(id)}>
            <div className='question-item__group-title'>
                <div className='question-item__title'>{question}</div>
            </div>
            <Collapse isOpened={activeItem === id}>
                <div className='question-item__text'>{answer}</div>
            </Collapse>
        </div>
    );
};
