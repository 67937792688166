export default function Arrow() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='17'
            viewBox='0 0 16 17'
            fill='none'>
            <path
                d='M0 8.98242L1.41 10.3924L7 4.81242V16.9824H9V4.81242L14.58 10.4024L16 8.98242L8 0.982422L0 8.98242Z'
                fill='white'
            />
        </svg>
    );
}
