import image1 from '../../utils/images/partners/image1.png';
import image2 from '../../utils/images/partners/image2.png';
import image3 from '../../utils/images/partners/image3.png';
import image4 from '../../utils/images/partners/image4.png';
import image5 from '../../utils/images/partners/image5.png';
import image6 from '../../utils/images/partners/image6.png';
import image7 from '../../utils/images/partners/image7.png';
import image8 from '../../utils/images/partners/image8.png';
import image9 from '../../utils/images/partners/image9.png';
import image10 from '../../utils/images/partners/image10.png';
import image12 from '../../utils/images/partners/image12.png';
import image13 from '../../utils/images/partners/image13.png';
import image14 from '../../utils/images/partners/image14.png';
import image15 from '../../utils/images/partners/image15.png';
import image16 from '../../utils/images/partners/image16.png';
import image17 from '../../utils/images/partners/image17.png';
import image18 from '../../utils/images/partners/image18.png';
import image19 from '../../utils/images/partners/image19.png';

export const partnersIcons = [
    { image: image8, alt: 'premium' },
    { image: image7, alt: 'MTC cashback' },
    { image: image9, alt: 'menu' },
    { image: image19, alt: 'атол' },
    { image: image2, alt: 'cofix' },
    { image: image6, alt: 'cannam chicken' },
    { image: image12, alt: 'академия кофе' },
    { image: image16, alt: 'one palce coffee' },
    { image: image13, alt: 'greem house' },
    { image: image14, alt: 'vлаvаше' },
    { image: image5, alt: 'iiko' },
    { image: image17, alt: 'сбп' },
    { image: image4, alt: 'payme' },
    { image: image3, alt: 'click' },
    { image: image18, alt: 'суши маркет' },
    { image: image10, alt: 'best2pay' },
    { image: image15, alt: 'бургер кит' },
    { image: image1, alt: 'даблби' },
];
