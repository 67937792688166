import { partnersIcons } from '../../utils/lists/partners';
import './partners.scss'
// @ts-ignore
import Marquee from 'react-double-marquee';

export const Partners = () => {
    return (
        <section className='partners'>
            <Marquee direction='left' speed='0.2'>
                {partnersIcons.map(partner =>
                    <img key={partner.alt} src={partner.image} alt={partner.alt} />
                )}
            </Marquee>
        </section>
    )
}