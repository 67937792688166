import { FC } from 'react';
import './productCard.scss';
import clsx from 'clsx';

interface IProductCardProps {
    
    /** Контент карточки. */
    children: any;

    /** Дополнительные классы стилей карточки. */
    className?: string;
}

export const ProductCard: FC<IProductCardProps> = (props) => {
    const {children, className} = props;
    
    return (
        <div className={clsx('product-card', className)}>
            {children}
        </div>
    )
}