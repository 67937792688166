import { useState } from 'react';
import imageQuestions from '../../utils/images/questions.png';
import { questions } from '../../utils/lists/questions';
import { QuestionItem } from '../questionItem/questionItem';
import './questions.scss';

export const Questions = () => {
    const [activeItem, setActiveItem] = useState<null | number>(null);

    const onSetActiveItem = (id: number) => {
        setActiveItem((activeItem) => {
            if (id === activeItem) {
                return null;
            } else {
                return id;
            }
        });
    };

    return (
        <div className='__wrapper'>
            <section className='questions'>
                <div className='questions__title'>Самые важные вопросы</div>

                <div className='questions__group-content'>
                    <img
                        src={imageQuestions}
                        alt='Изображение интерфейсов'
                        className='questions__image'
                    />

                    <div>
                        {questions.map((question) => (
                            <QuestionItem
                                key={question.id}
                                id={question.id}
                                activeItem={activeItem}
                                setActiveItem={onSetActiveItem}
                                question={question.question}
                                answer={question.answer}
                            />
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};
