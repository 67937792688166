import { ProductCard } from '../productCard/productCard';
import { Button } from '../button/button';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RequestModal } from '../requestModal/requestModal';
import small from '../../utils/images/tariffs/small.svg';
import big from '../../utils/images/tariffs/big.svg';
import average from '../../utils/images/tariffs/average.svg';
import allInclusive from '../../utils/images/tariffs/allInclusive.svg';
import sale17 from '../../utils/images/tariffs/sale17.svg';
import sale33 from '../../utils/images/tariffs/sale33.svg';
import allPoints from '../../utils/images/tariffs/allPoints.svg';
import './tariffs.scss';

export const Tariffs = () => {
    const Mobile = useMediaQuery('(max-width:500px)');
    const [activeModal, setActiveModal] = useState(false);

    const onOpenModal = () => {
        setActiveModal(true);
    };

    const onCloseModal = () => {
        setActiveModal(false);
    };

    return (
        <div className='__wrapper'>
            <section className='tariffs'>
                <div className='tariffs__title'>тарифы</div>

                <div className='tariffs__group-cards'>
                    <ProductCard className='tariffs__card'>
                        <img
                            src={small}
                            alt='Малый бизнес'
                            className='tariffs__card-image'
                        />
                        {!Mobile && (
                            <div className='tariffs__card-title'>
                                Малый <br /> бизнес
                            </div>
                        )}
                        {Mobile && (
                            <div className='tariffs__card-title'>
                                Малый бизнес
                            </div>
                        )}
                        <div className='tariffs__card-price'>1 500₽</div>
                        <div className='tariffs__card-text'>
                            с одного терминала в месяц
                        </div>
                        <div className='tariffs__card-text'>
                            до 10 терминалов
                        </div>
                        <Button
                            theme='green'
                            className='tariffs__card-button'
                            onClick={onOpenModal}>
                            Подключить
                        </Button>
                    </ProductCard>

                    <ProductCard className='tariffs__card'>
                        <img
                            src={average}
                            alt='Средний бизнес'
                            className='tariffs__card-image'
                        />
                        <img
                            src={sale17}
                            alt='Информация о скидке'
                            className='tariffs__card-info-image'
                        />
                        {!Mobile && (
                            <div className='tariffs__card-title'>
                                Средний <br /> бизнес
                            </div>
                        )}
                        {Mobile && (
                            <div className='tariffs__card-title'>
                                Средний бизнес
                            </div>
                        )}
                        <div className='tariffs__card-price'>1 250₽</div>
                        <div className='tariffs__card-text'>
                            с одного терминала в месяц
                        </div>
                        <div className='tariffs__card-text'>
                            от 11 до 20 терминалов
                        </div>

                        <Button
                            theme='green'
                            className='tariffs__card-button'
                            onClick={onOpenModal}>
                            Подключить
                        </Button>
                    </ProductCard>

                    <ProductCard className='tariffs__card'>
                        <img
                            src={big}
                            alt='Крупный бизнес'
                            className='tariffs__card-image '
                        />
                        <img
                            src={sale33}
                            alt='Информация о скидке'
                            className='tariffs__card-info-image'
                        />
                        {!Mobile && (
                            <div className='tariffs__card-title'>
                                Крупный <br /> бизнес
                            </div>
                        )}
                        {Mobile && (
                            <div className='tariffs__card-title'>
                                Крупный бизнес
                            </div>
                        )}
                        <div className='tariffs__card-price'>1 000₽</div>
                        <div className='tariffs__card-text'>
                            с одного терминала в месяц
                        </div>
                        <div className='tariffs__card-text'>
                            от 21 терминала
                        </div>

                        <Button
                            theme='green'
                            className='tariffs__card-button'
                            onClick={onOpenModal}>
                            Подключить
                        </Button>
                    </ProductCard>

                    <ProductCard className='tariffs__card'>
                        <img
                            src={allInclusive}
                            alt='Все включено'
                            className='tariffs__card-image'
                        />
                        <img
                            src={allPoints}
                            alt='Информация о подключении'
                            className='tariffs__card-info-image'
                        />
                        {!Mobile && (
                            <div className='tariffs__card-title'>
                                все <br /> включено
                            </div>
                        )}
                        {Mobile && (
                            <div className='tariffs__card-title'>
                                все включено
                            </div>
                        )}
                        <div className='tariffs__card-price'>400 000₽</div>
                        <div className='tariffs__card-text'>единая сумма</div>
                        <div className='tariffs__card-text'>
                            и больше никаких платежей
                        </div>

                        <Button
                            theme='green'
                            className='tariffs__card-button'
                            onClick={onOpenModal}>
                            Подключить
                        </Button>
                    </ProductCard>
                </div>
                <RequestModal active={activeModal} onClose={onCloseModal} />
            </section>
        </div>
    );
};
